import {
  findFileByFindType,
  delDraftBox,
  findCourseGroupByCourseId,
  findForeignFileByFindType,
} from "./browserApi";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import {
  getBackTablesList,
  getCapitalLists,
  getTradeLists,
} from "../../trade-manage/tradeMethods";
import { repHtml } from "../../../teacher-manage/fine-report/fineMethods";
export const getDrafrLists = (data) => {
  data.disabled = true
  let parame = {
    classId: JSON.parse(sessionStorage.getItem("classId")),
    turnOnTimeRound: 1,
    goodType: data.goods ? 1 : 0,
    findType: data.pageType,
    filetype: data.reportValue,
    fileName: data.fileName,
    startTime: data.startTime,
    endTime: data.endTime,
    acid: data.groupValue,
    // lastState: data.lastState,
    // filetype: data.filetype,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  data.spreadTable = []
  data.total = ''
  if (data.financeRadio == "外汇") {
    parame.acId = parame.acid;
    parame.courseId = parame.classId;
    findForeignFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTable = newRes.currencyManagement.list;
        data.total = newRes.currencyManagement.total;
        dealDate(data.spreadTable, ["date"]);
        for (let i in data.spreadTable) {
          data.spreadTable[i].whetherState =
            data.spreadTable[i].whetherToDelay === 0 ? "未延迟" : "延迟";
          data.spreadTable[i].goodTypes =
            data.spreadTable[i].goodType === 0 ? "未评级" : "优秀";
        }
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  } else {
    findFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTable = newRes.reportManagementPageInfo.list;
        data.total = newRes.reportManagementPageInfo.total;
        dealDate(data.spreadTable, ["date"]);
        for (let i in data.spreadTable) {
          data.spreadTable[i].whetherState =
            data.spreadTable[i].whetherToDelay === 0 ? "未延迟" : "延迟";
          data.spreadTable[i].goodTypes =
            data.spreadTable[i].goodType === 0 ? "未评级" : "优秀";
        }
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  }
  data.showHtml = false
};
// 小组下拉
export const getGroupLists = (data) => {
  let param = {
    classId: JSON.parse(sessionStorage.getItem("classId")),
  };
  findCourseGroupByCourseId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.groupOption = newRes.courseGroups;
    }
  });
};

// 格式化 日期
export const dealDate = (data, dealArr) => {
  let arr = data;
  for (let i = 0; i < arr.length; i++) {
    let res = arr[i];
    for (let j in dealArr) {
      res[dealArr[j]] =
        res[dealArr[j]].toString().slice(0, 4) +
        "-" +
        res[dealArr[j]].toString().slice(4, 6) +
        "-" +
        res[dealArr[j]].toString().slice(6);
    }
  }
  return arr;
};

export const DateChanges = (val, data) => {
  data.pageSize = 10;
  data.currentPage = 1;
  if (val === null) {
    data.startTime = undefined;
    data.endTime = undefined;
  } else {
    data.startTime = dayjs(val[0]).format("YYYYMMDD");
    // data.startTime = Date.parse()/1000
    // data.endTime = Date.parse(val[1])/1000
    data.endTime = dayjs(val[1]).format("YYYYMMDD");
  }
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getDrafrLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getDrafrLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    fieldId: item.fileId,
  };
  delDraftBox(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getDrafrLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data, router) => {
  data.showHtml = true;
  repHtml(data, val.item.fileId);
};
