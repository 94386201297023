<template>
  <div>
    <div class="filebor">
      <div class="filebor_border" v-if="reoprtList.length">
        <!-- reoprtList  {{item.name}}  {{item.date}}-->
        <div class="border-style" v-for="(item, index) in reoprtList" style="margin-top: 7px" @click="moreRou(item)">
          <div class="filebor_head">{{ item.name }}</div>
          <div class="filebor_content"> 点击查看详情 </div>
          <div class="filebor_text">
            <span>{{ item.date }}</span>
            <!-- <span class="filebor_name">人名需要后端返</span> -->
            <!-- <span>{{item.date}}</span> -->
            <span class="filebor_name">{{ item.userName }}</span>
          </div>
        </div>
      </div>
      <template v-else>
        <el-empty description="暂无优秀研报" />
      </template>
    </div>
    <el-dialog v-model="showHtml" :close-on-click-modal="false" width="80%">
      <div class="title" style="margin-top: -20px;">{{ name }}</div>
      <div style="height:50vh; overflow: auto;color: #03032C;" v-html="files"></div>
    </el-dialog>
  </div>
</template>

<script>
import { findFileByFindType } from "../../../upreport-manage/Upreport-Browser/browserApi";
import { dealDate } from "../../../upreport-manage/Upreport-Browser/browserMethods";
import { findFileByFileId } from "../../../stock-manage/stockApi"
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "goodReport",
  setup() {
    let router = useRouter()

    let data = reactive({
      files: '',
      name: '',
      showHtml: false,
      reoprtList: []
    })
    onMounted(() => {
      getReport()
    })

    let moreRou = (item) => {
      let param = {
        fieldIds: [item.fileId]
      }
      findFileByFileId(param).then((res) => {
        if (res.data.code == 200) {
          data.showHtml = true
          data.files = res.data.data.tfundFundfiles[0].files
          data.name = res.data.data.tfundFundfiles[0].name
        }
      })
      // router.push({ path: '/course/report/browser', query: { item: item.fileId } })
    }
    let getReport = () => {
      let parame = {
        classId: JSON.parse(sessionStorage.getItem('classId')),
        findType: 1,
        goodType: '1',
        pageSize: 10,
        page: 1,
      }
      findFileByFindType(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          data.reoprtList = newRes.reportManagementPageInfo.list
          // for (let i in data.reoprtList){
          //   if (data.reoprtList[i].plabel.length>0){
          //     data.reoprtList[i].plabel[0] = data.reoprtList[i].plabel[0].replace("/<img(.*?)(?=[\\]\\},/>)/g", "R")
          //   }
          // }
          dealDate(data.reoprtList, ['date'])
        }
      })
    }

    return {
      ...toRefs(data),
      moreRou
    }
  }
}
</script>

<style lang="less">
.filebor {
  padding: 20px 38px;
  // height: 427px;
  // overflow: auto;
}

.filebor_border {
  display: flex;
  flex-wrap: wrap;
  height: 90%;
  overflow: hidden;
  justify-content: space-between;
  /* 隐藏y轴滚动条 */
  margin-right: -63px;
  /* Maximum width of scrollbar */
  padding-right: 50px;

  /* Maximum width of scrollbar */
  // overflow-y: scroll;
  .border-style {
    width: 32%;
    height: 185px;
    background: #FBFBFB;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 30px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #4D4B56;
    line-height: 24px;
    margin-bottom: 14px;

    .filebor_head {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #03032C;
    }

    .filebor_content {
      color: blue;
      line-height: 60px;
      margin: 16px   10px;
    }
  }
}

.commonStyle {
  background-color: white;
  display: inline-block;
}

/*.border-style{*/
/*}*/
/*.border-style:hover{*/
/*  border-bottom: 1px solid #f2f2f2;*/
/*}*/
.border-style {
  cursor: pointer;
}

.border-style .filebor_text {
  // letter-spacing: 1px;
  // line-height: 30px;
  // color: rgba(0, 0, 0, 0.5);
  // font-size: 12px;
  // margin-top: 4px;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3;
  // overflow: hidden;
  display: flex;

  .filebor_name {
    margin-left: 10px;
  }
}

/*@keyframes border-style {*/
/*   16.65% {*/
/*     -webkit-transform: translateX(8px); transform: translateX(8px);*/
/*   }*/
/*   33.3% {*/
/*     -webkit-transform: translateX(-6px); transform: translateX(-6px);*/
/*   }*/
/*   49.95% {*/
/*     -webkit-transform: translateX(4px); transform: translateX(4px);*/
/*   }*/
/*   66.6% {*/
/*     -webkit-transform: translateX(-2px); transform: translateX(-2px);*/
/*   }*/
/*   83.25% {*/
/*     -webkit-transform: translateX(1px); transform: translateX(1px);*/
/*   }*/

/*   100% {*/
/*     -webkit-transform: translateX(0); transform: translateX(0);*/
/*   }*/
/* }*/
/*.border-style:hover, .border-style:focus, .border-style:active {*/
/*  animation: border-style 1s ease-in-out 1;*/
/*}*/

.htmlReport img {
  display: none;
}

.imgClass img {
  width: 100%;
  height: 100%;
}

.title {
  text-align: center;
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #03032C;
  line-height: 31px;
  margin-bottom: 20px;
}
</style>
